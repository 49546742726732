/* Set default styles for all elements */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Set font and background styles for body */
body {
  font-family: 'Uber Move Text', sans-serif;
  background-color: #f2f2f2;
}

/* Set styles for headings */
h1,
h2 {
  text-align: center;
  color: #333;
  margin-top: 20px;
  margin-bottom: 20px; /* Add margin-bottom for spacing */
}

/* Set styles for container */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Set styles for form */
.form {
  width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 20px auto; /* Add margin for spacing */
}

/* Set styles for labels */
.form label {
  display: block;
  margin-bottom: 10px;
  color: #333;
}

/* Set styles for inputs, select, and button */
.form select,
.form input[type='number'],
.form button {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

/* Set styles for number input */
.form input[type='number'] {
  width: 100%;
}

/* Set styles for button */
.form button {
  background-color: #01af4d;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 10px; /* Add margin-top for spacing */
}

/* Set hover styles for button */
.form button:hover {
  background-color: #45a049;
}

/* Set styles for result section */
.result {
  margin-top: 40px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Set styles for valid sign */
.valid-sign {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Set styles for result image */
.result img {
  max-width: 10%;
  height: auto;
  margin-bottom: 10px;
  margin: 0 auto;
}

/* Set styles for result table */
.result table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Set styles for table headers and data cells */
.result th,
.result td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

/* Set styles for table headers */
.result th {
  background-color: #f2f2f2;
  color: #333;
}

/* Set styles for table data cells */
.result td {
  word-break: break-word;
  white-space: normal;
}

/* Set styles for error messages */
.error {
  color: #ff0000;
}
h2.warning {
  color: red;
}

/* Set styles for centering elements */
.center {
  display: block;
  margin: 0 auto;
}

/* Set styles for logo */
.logo {
  display: block;
  margin: 20px auto;
  max-width: 70px;
  height: auto;
}

/* Media queries for mobile devices */
@media only screen and (max-width: 600px) {
  /* Adjust the layout and styling for mobile devices */

  /* Set font size for body */
  body {
    font-size: 14px;
  }

  /* Set height to auto for container */
  .container {
    height: auto;
  }

  /* Set width to 90% for form */
  .form {
    width: 90%;
  }

  /* Set padding to 10px for result section */
  .result {
    padding: 10px;
  }

  /* Set max width to 50% for result image */
  .result img {
    max-width: 50%;
  }

  /* Set font size to 12px for result table */
  .result table {
    font-size: 12px;
  }

  /* Set width to 20px for valid sign */
  .valid-sign {
    width: 20px;
  }

  /* Set table to display as block and adjust width */
  .result table {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
}
/* Set styles for reload button */
button.reload {
  background-color: #01af4d;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 20px; /* Add margin-top for spacing */
  width: 20%;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

/* Set hover styles for reload button */
.form button.reload:hover {
  background-color: #45a049;
}
